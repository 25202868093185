<template>
  <div class="reviews">
    <Score :score="this.score" />

    <Reviews :reviews="reviews" />
  </div>
</template>

<script>
import Score from './components/Score.vue'
import Reviews from './components/Reviews.vue'

import axios from 'axios'

export default {
  name: 'app',
  data: function() {
    return {
      score: {},
      reviews: {}
    }
  },
  components: {
    Score,
    Reviews
  },
  mounted: function() {
    // Get average scores
    axios
      .get('https://www.zipperclinics.nl/api/avgrating')
      .then(response => (this.score = response.data))

    // Get all reviews
    axios
      .get('https://www.zipperclinics.nl/api/ratings/')
      .then(response => (this.reviews = response.data))
  }
}
</script>

<style lang="scss">
  .reviews {
    padding-top: 40px;
    padding-bottom: 40px;

    * {
      box-sizing: border-box;
    }

    &__score {
      background-color: rgba(#dcdddb, .5);
      padding: 20px;
    }

    &__reviews {
      padding: 20px;
    }

    @media screen and (min-width: 768px) {
      display: flex;

      &__score {
        flex: 0 0 32%;
        max-width: 32%;
        margin-right: 3%;
      }

      &__reviews {
        flex: 0 0 65%;
        max-width: 65%;
        padding: 0;
      }
    }
  }
</style>

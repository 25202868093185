<template>
  <a :href="review['URL waardering']" class="review" target="_blank" rel="noopener noreferrer" :class="{ 'fading': review.fading }">
    <div class="review__score">
      <div class="title">
        {{ review['Cijfer gemiddelde'] }}
      </div>
    </div>
    <div class="review__text">
      {{ review['Beschrijving'] }}
    </div>
  </a>
</template>

<script>
export default {
  name: 'Review',
  props: ['review']
}
</script>

<style lang="scss">
  .review {
    align-content: center;
    animation: scrollin 1s;
    border: 1px solid rgba(#dcdddb, .5);
    color: inherit;
    display: flex;
    margin-bottom: 10px;
    padding: 20px;
    text-decoration: none;
    transition: all 350ms;

    &:last-child {
      animation: fadein 1s;
    }

    &.fading {
      opacity: 0;
    }

    &:hover {
      background-color: rgba(#dcdddb, .25);
    }

    &__score {
      margin-right: 20px;

      .title {
        font-size: 60px;
        margin-bottom: 0;
      }
    }

    &__text {
      align-self: center;
    }
  }

  @keyframes fadein {
    from {
      opacity: 0;
      transform: translateY(100px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes scrollin {
    from {
      transform: translateY(100px);
    }
    to {
      transform: translateY(0);
    }
  }
</style>

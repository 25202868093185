<template>
  <div class="reviews__reviews">

    <Review v-for="(review, index) in this.reviews" :key="review['ID waardering']" :review="review" class="review" v-if="index < 3" />

  </div>
</template>

<script>
import Review from './Review.vue'

export default {
  name: 'Reviews',
  data: function() {
    return {
    }
  },
  props: ['reviews'],
  mounted: function() {
    let self = this
    window.setTimeout(function() {
      self.scrollUp()
    }, 4500)
  },
  methods: {
    scrollUp: function() {
      let self = this
      this.reviews[0].fading = true

      window.setTimeout(function() {
        let item = self.reviews.shift()
        item.fading = false
        self.reviews.push(item)
      }, 500)

      window.setTimeout(function() {
        self.scrollUp()
      }, 4500)
    }
  },
  components: {
    Review
  }
}
</script>

<template>
  <div class="reviews__score score">
    <div class="score__image">
      <img src="@/assets/logo_zkn.svg">
    </div>

    <div class="score__title">
      <h4 class="title title--h4">Score op Zorgkaart Nederland</h4>
    </div>

    <div class="score__score">
      <div class="title">
        {{ averageScore }}
      </div>
    </div>

    <a href="https://www.zorgkaartnederland.nl/zorginstelling/esthetisch-medisch-centrum-zipper-clinics-enschede-10000020" target="_blank" rel="noopener noreferrer" class="score__count link">{{ score.ratingCount }} beoordelingen</a>

  </div>
</template>

<script>
export default {
  name: 'Score',
  props: ['score'],
  computed: {
    averageScore: function() {
      return this.score.avgScore && this.score.avgScore.toFixed(1)
    }
  }
}
</script>

<style scoped lang="scss">
  .score {
    margin-bottom: 10px;
    text-align: center;

    &__image {
      margin-bottom: 10px;

      img {
        max-width: 100%;
      }
    }

    &__title {
      margin-bottom: 20px;
      color: #001b51;
    }

    &__score {

      .title {
        font-size: 70px;
      }
    }

    &__count {
      opacity: .6;

      &:hover {
        opacity: 1;
      }
    }
  }
</style>
